<script setup>
	// Props
	const props = defineProps({
		page: {
			type: Object,
			required: true,
		},
	});

	const { page } = toRefs(props);
</script>

<!-- These "templates" need to return a single root node -->
<!-- Otherwise Vue get's mad -->
<template>
	<div>
		<main id="main" key="page" class="mess-page page.vue" tabindex="-1">
			<MessRenderContent :page="page" />
		</main>
	</div>
</template>
